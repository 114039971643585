var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _vm.isOld
              ? _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm revisionLayout",
                    attrs: {
                      title: "LBLREV",
                      topClass: "topcolor-orange",
                      isRevision: _vm.isRevision,
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: _vm.editable && _vm.isRevision,
                              disabled: _vm.disabled,
                              editable: _vm.editable && _vm.isRevision,
                              label: "LBLREVNO",
                              name: "revisionNum",
                            },
                            model: {
                              value: _vm.license.revisionNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.license, "revisionNum", $$v)
                              },
                              expression: "license.revisionNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "LBLREVPERIOD",
                              name: "regDtStr",
                            },
                            model: {
                              value: _vm.license.regDtStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.license, "regDtStr", $$v)
                              },
                              expression: "license.regDtStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "LBLREVUSER",
                              name: "regUserName",
                            },
                            model: {
                              value: _vm.license.regUserName,
                              callback: function ($$v) {
                                _vm.$set(_vm.license, "regUserName", $$v)
                              },
                              expression: "license.regUserName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: _vm.editable && _vm.isRevision,
                              disabled: _vm.disabled,
                              editable: _vm.editable && _vm.isRevision,
                              label: "LBLREVREASON",
                              name: "revisionContent",
                            },
                            model: {
                              value: _vm.license.revisionContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.license, "revisionContent", $$v)
                              },
                              expression: "license.revisionContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBL0010256" },
              },
              [
                _c(
                  "template",
                  { slot: "card-select" },
                  [
                    _vm.popupParam.limLicenseId
                      ? _c("c-select", {
                          attrs: {
                            editable: !_vm.isRevision,
                            comboItems: _vm.gridRev.data,
                            type: "custom",
                            typetext: "LBL0010257",
                            itemText: "revisionNum",
                            itemValue: "limLicenseId",
                            name: "selectedlimLicenseId",
                            label: "",
                          },
                          on: { input: _vm.rowClickRev },
                          model: {
                            value: _vm.selectedlimLicenseId,
                            callback: function ($$v) {
                              _vm.selectedlimLicenseId = $$v
                            },
                            expression: "selectedlimLicenseId",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.editable &&
                                _vm.isOld &&
                                !_vm.isRevision &&
                                _vm.saveEditable &&
                                !_vm.disabled,
                              expression:
                                "editable && isOld && !isRevision && saveEditable && !disabled",
                            },
                          ],
                          attrs: { label: "LBLREV", icon: "restart_alt" },
                          on: { btnClicked: _vm.setRevision },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.editable &&
                                _vm.isOld &&
                                _vm.isRevision &&
                                _vm.saveEditable &&
                                !_vm.disabled,
                              expression:
                                "editable && isOld && isRevision && saveEditable && !disabled",
                            },
                          ],
                          attrs: { label: "LBLREVCANCEL", icon: "restart_alt" },
                          on: { btnClicked: _vm.cancelRevision },
                        }),
                        _vm.editable &&
                        _vm.isOld &&
                        _vm.deleteEdtiable &&
                        !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.deleteData },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.saveEditable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.license,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          afterIcon:
                            _vm.editable && !_vm.isOld && !_vm.disabled
                              ? [
                                  {
                                    name: "search",
                                    click: true,
                                    callbackName: "searchLicenseKindId",
                                    color: "teal",
                                  },
                                  {
                                    name: "close",
                                    click: true,
                                    callbackName: "removeLicenseKindId",
                                    color: "red",
                                  },
                                ]
                              : null,
                          editable: _vm.editable,
                          disabled: _vm.isOld || _vm.disabled,
                          readonly: true,
                          label: "LBL0010249",
                          name: "limLicenseKindName",
                        },
                        on: {
                          searchLicenseKindId: _vm.searchLicenseKindId,
                          removeLicenseKindId: _vm.removeLicenseKindId,
                        },
                        model: {
                          value: _vm.license.limLicenseKindName,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "limLicenseKindName", $$v)
                          },
                          expression: "license.limLicenseKindName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          label: "LBL0010248",
                          name: "licenseNo",
                        },
                        model: {
                          value: _vm.license.licenseNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "licenseNo", $$v)
                          },
                          expression: "license.licenseNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: true,
                          label: "LBL0010245",
                          name: "relatedLawsName",
                        },
                        model: {
                          value: _vm.license.relatedLawsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "relatedLawsName", $$v)
                          },
                          expression: "license.relatedLawsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.isOld || _vm.disabled,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.license.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "plantCd", $$v)
                          },
                          expression: "license.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "LBL0010250",
                          name: "licenseStartDate",
                        },
                        model: {
                          value: _vm.license.licenseStartDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "licenseStartDate", $$v)
                          },
                          expression: "license.licenseStartDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "LBL0010251",
                          name: "licenseEndDate",
                        },
                        model: {
                          value: _vm.license.licenseEndDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "licenseEndDate", $$v)
                          },
                          expression: "license.licenseEndDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "LBL0010252",
                          name: "relatedDeptCd",
                        },
                        model: {
                          value: _vm.license.relatedDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "relatedDeptCd", $$v)
                          },
                          expression: "license.relatedDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-field", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          data: _vm.license,
                          type: "user",
                          label: "LBL0010258",
                          name: "chargeUserId",
                        },
                        model: {
                          value: _vm.license.chargeUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "chargeUserId", $$v)
                          },
                          expression: "license.chargeUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: true,
                          codeGroupCd: "LICENSE_CREATE_TYPE_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "licenseCreateTypeCd",
                          label: "LBL0010253",
                        },
                        model: {
                          value: _vm.license.licenseCreateTypeCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "licenseCreateTypeCd", $$v)
                          },
                          expression: "license.licenseCreateTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "LBLREMARK",
                          name: "remark",
                        },
                        model: {
                          value: _vm.license.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.license, "remark", $$v)
                          },
                          expression: "license.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" },
          [
            _c(
              "c-table",
              {
                ref: "materialTable",
                attrs: {
                  title: "LBL0010260",
                  tableId: "materialTable",
                  columns: _vm.gridMaterial.columns,
                  data: _vm.license.materials,
                  columnSetting: false,
                  filtering: false,
                  usePaging: false,
                  hideBottom: true,
                  editable: _vm.editable && !_vm.disabled,
                  gridHeight: "252px",
                  selection: "multiple",
                  rowKey: "mdmChemMaterialId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addRowMaterial },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "제외", icon: "remove" },
                              on: { btnClicked: _vm.removeRowMaterial },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            staticStyle: { "padding-top": "20px !important" },
          },
          [
            _c("c-upload", {
              attrs: {
                attachInfo: _vm.attachInfoRel,
                editable: _vm.editable && !_vm.disabled,
                label: "LBL0010261",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-upload", {
              attrs: {
                attachInfo: _vm.attachInfo,
                editable: _vm.editable && !_vm.disabled,
                label: "LBL0010262",
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }