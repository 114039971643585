<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--개정-->
        <c-card 
          v-if="isOld"
          title="LBLREV" 
          class="cardClassDetailForm revisionLayout" 
          topClass="topcolor-orange"
          :isRevision="isRevision">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 col-xl-1">
              <!--개정번호-->
              <c-text
                :required="editable && isRevision"
                :disabled="disabled"
                :editable="editable && isRevision"
                label="LBLREVNO"
                name="revisionNum"
                v-model="license.revisionNum">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--개정일시-->
              <c-text
                :editable="false"
                label="LBLREVPERIOD"
                name="regDtStr"
                v-model="license.regDtStr">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 col-xl-1">
              <!--개정자-->
              <c-text
                :editable="false"
                label="LBLREVUSER"
                name="regUserName"
                v-model="license.regUserName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <!--개정사유-->
              <c-text
                :required="editable && isRevision"
                :disabled="disabled"
                :editable="editable && isRevision"
                label="LBLREVREASON"
                name="revisionContent"
                v-model="license.revisionContent">
              </c-text>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <!--인허가 정보-->
        <c-card class="cardClassDetailForm" title="LBL0010256">
          <template slot="card-select">
            <!--개정이력-->
            <c-select
            v-if="popupParam.limLicenseId"
              :editable="!isRevision"
              :comboItems="gridRev.data"
              type="custom"
              typetext="LBL0010257"
              itemText="revisionNum"
              itemValue="limLicenseId"
              name="selectedlimLicenseId"
              label=""
              v-model="selectedlimLicenseId"
              @input="rowClickRev"
            ></c-select>
          </template>
          <template slot="card-button">
            <q-btn-group outline >
              <!--개정-->
              <c-btn
                v-show="editable && isOld && !isRevision && saveEditable && !disabled"
                label="LBLREV"
                icon="restart_alt"
                @btnClicked="setRevision" />
              <!--개정취소-->
              <c-btn
                v-show="editable && isOld && isRevision && saveEditable && !disabled"
                label="LBLREVCANCEL"
                icon="restart_alt"
                @btnClicked="cancelRevision" />
              <!--삭제-->
              <c-btn
                v-if="editable && isOld && deleteEdtiable && !disabled"
                label="LBLREMOVE"
                icon="remove"
                @btnClicked="deleteData" />
              <!--저장-->
              <c-btn
                v-if="editable && saveEditable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="license"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <!--인허가종류-->
              <c-text
                :required="true"
                :afterIcon="editable && !isOld && !disabled ? [
                  { name: 'search', click: true, callbackName: 'searchLicenseKindId', color: 'teal' },
                  { name: 'close', click: true, callbackName: 'removeLicenseKindId', color: 'red' }
                ] : null"
                :editable="editable"
                :disabled="isOld || disabled"
                :readonly="true"
                label="LBL0010249"
                name="limLicenseKindName"
                v-model="license.limLicenseKindName"
                @searchLicenseKindId="searchLicenseKindId"
                @removeLicenseKindId="removeLicenseKindId">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--인허가번호-->
              <c-text
                :required="true"
                :disabled="disabled"
                :editable="editable"
                label="LBL0010248"
                name="licenseNo"
                v-model="license.licenseNo">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--관련법규-->
              <c-text
                :editable="editable"
                :disabled="true"
                label="LBL0010245"
                name="relatedLawsName"
                v-model="license.relatedLawsName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                :disabled="isOld || disabled"
                type="edit"
                name="plantCd"
                v-model="license.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--인허가시작일-->
              <c-datepicker
                :editable="editable"
                :disabled="disabled"
                label="LBL0010250"
                name="licenseStartDate"
                v-model="license.licenseStartDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--인허가종료일-->
              <c-datepicker
                :editable="editable"
                :disabled="disabled"
                label="LBL0010251"
                name="licenseEndDate"
                v-model="license.licenseEndDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--관련부서-->
              <c-dept
                :editable="editable"
                :disabled="disabled"
                label="LBL0010252"
                name="relatedDeptCd"
                v-model="license.relatedDeptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--인허가담당자-->
              <c-field
                :editable="editable"
                :disabled="disabled"
                :data="license"
                type="user"
                label="LBL0010258"
                name="chargeUserId"
                v-model="license.chargeUserId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <!--인허가 등록 구분-->
              <c-select
                :editable="editable"
                :disabled="true"
                codeGroupCd="LICENSE_CREATE_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="licenseCreateTypeCd"
                label="LBL0010253"
                v-model="license.licenseCreateTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!--비고-->
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="LBLREMARK"
                name="remark"
                v-model="license.remark">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <!--인허가관련 화학자재-->
        <c-table
          ref="materialTable"
          title="LBL0010260"
          tableId="materialTable"
          :columns="gridMaterial.columns"
          :data="license.materials"
          :columnSetting="false"
          :filtering="false"
          :usePaging="false"
          :hideBottom="true"
          :editable="editable&&!disabled"
          gridHeight="252px"
          selection="multiple"
          rowKey="mdmChemMaterialId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn label="추가" v-if="editable&&!disabled" icon="add" @btnClicked="addRowMaterial" />
              <c-btn label="제외" v-if="editable&&!disabled" icon="remove" @btnClicked="removeRowMaterial" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:20px !important">
        <!--인허가 관련서류-->
        <c-upload
          :attachInfo="attachInfoRel"
          :editable="editable && !disabled"
          label="LBL0010261">
        </c-upload>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--인허가증 파일-->
        <c-upload
          :attachInfo="attachInfo"
          :editable="editable && !disabled"
          label="LBL0010262">
        </c-upload>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'license-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        limLicenseId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      selectedlimLicenseId: null,
      license: {
        limLicenseId: '',  // 인허가 일련번호
        plantCd: '',  // 사업장 코드
        licenseNo: '',  // 인허가증 번호
        relatedLawsCd: '',  // 관련법규
        relatedLawsName: '',  // 관련법규
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',  // 인허가종류 알련번호
        licenseStartDate: '',  // 인허가시작일
        licenseEndDate: '',  // 인허가종료일
        relatedDeptCd: '',  // 관련부서
        chargeUserId: '',  // 인허가담당자
        remark: '',  // 비고
        licenseCreateTypeCd: '',  // 인허가 등록 구분코드
        relLimLicenseReviewId: '',  // 검토후등록-인허가 검토 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        sysRevision: '1',  // 시스템 개정번호
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        regDtStr: '',
        regUserName: '',
        materials: [],
        deleteMaterials: [],
      },
      gridMaterial: {
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재명
            label: 'LBL0010267',
            align: 'left',
            sortable: true,
          },
        ],
      },
      gridRev: {
        data: [],
      },
      attachInfoRel: {
        isSubmit: '',
        taskClassCd: 'LICENSE_FILE_REL',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'LICENSE_FILE',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      editable: true,
      isSave: false,
      isRevision: false,
      mappingType: 'POST',
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveEditable() {
      // 작성자/인허가담당자/관련부서원만 수정가능
      return this.license.chargeUserId == this.$store.getters.user.userId 
        || this.license.relatedDeptCd == this.$store.getters.user.deptCd 
        || this.license.regUserId == this.$store.getters.user.userId
    },
    deleteEdtiable() {
      // 작성자만 삭제가능
      return this.license.regUserId == this.$store.getters.user.userId
    },
    disabled() {
      return this.license.limLicenseId !== this.popupParam.limLicenseId ? true : false;
    },
    isOld() {
      return Boolean(this.popupParam.limLicenseId)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.card.get.url;
      this.saveUrl = transactionConfig.sop.lim.lincense.card.insert.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.card.delete.url;
      this.revlistUrl = selectConfig.sop.lim.lincense.card.list.url + '/revs';
      // code setting
      // list setting
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.isOld) {
        this.selectedlimLicenseId = this.popupParam.limLicenseId;
        this.getDetail(this.popupParam.limLicenseId, true);
      } else {
        this.license.licenseCreateTypeCd = 'LCTC000001'; //직접등록
      }
    },
    getDetail(_limLicenseId, _check) {
      this.$http.url = this.$format(this.detailUrl, _limLicenseId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.license, _result.data)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        this.$set(this.attachInfo, 'taskKey', _limLicenseId)
        this.$set(this.attachInfoRel, 'taskKey', _limLicenseId)
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
      },);
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.license.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.license.revisionNum = _result.data;
        this.license.revisionContent = '';
        this.license.regDtStr = '';
        this.license.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.limLicenseId)
        this.$set(this.attachInfo, 'isRev', true)
        this.$set(this.attachInfoRel, 'taskKey', '')
        this.$set(this.attachInfoRel, 'beforeTaskKey', this.popupParam.limLicenseId)
        this.$set(this.attachInfoRel, 'isRev', true)
      });
    },
    cancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.limLicenseId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfoRel, 'taskKey', this.popupParam.limLicenseId)
      this.$set(this.attachInfoRel, 'beforeTaskKey', '')
      this.$set(this.attachInfoRel, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridRev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row !== this.license.limLicenseId) {
          this.selectedlimLicenseId = row;
          this.getDetail(this.selectedlimLicenseId, false);
        }
      }
    },
    searchLicenseKindId() {
      this.popupOptions.title = 'LBL0010269'; // 관련법규/인허가종류 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.license.plantCd, // 사업장번호를 넘기면 해당사업장의 인허가현황에 포함된 인허가는 조회되지 않음
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licenseKindPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseKindId;
    },
    closeLicenseKindId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.license.relatedLawsName = data[0].relatedLawsName;
        this.license.relatedLawsCd = data[0].relatedLawsCd;
        this.license.limLicenseKindId = data[0].limLicenseKindId;
        this.license.limLicenseKindName = data[0].limLicenseKindName;
      }
    },
    removeLicenseKindId() {
      this.license.relatedLawsCd = '';
      this.license.relatedLawsName = '';
      this.license.limLicenseKindId = '';
      this.license.limLicenseKindName = '';
    },
    addRowMaterial() {
      this.popupOptions.title = 'LBL0010271'; // 인허가관련 화학자재 추가
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddRowMaterial;
    },
    closeAddRowMaterial(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data && _data.length > 0) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.license.materials, { mdmChemMaterialId: item.mdmChemMaterialId }) === -1) {
            this.license.materials.push({
              limLicenseId: this.popupParam.limLicenseId,
              mdmChemMaterialId: item.mdmChemMaterialId,
              materialName: item.materialName,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        });
      }
    },
    removeRowMaterial() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '제외하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.license.deleteMaterials) this.license.deleteMaterials = [];
            this.$_.forEach(selectData, item => {
              if (this.$_.findIndex(this.license.deleteMaterials, { mdmChemMaterialId: item.mdmChemMaterialId }) === -1
                && item.editFlag !== 'C') {
                  this.license.deleteMaterials.push(item)
                }
              this.license.materials = this.$_.reject(this.license.materials, item)
            })
            this.$refs['materialTable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.lim.lincense.card.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.lim.lincense.card.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';    // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.license.regUserId = this.$store.getters.user.userId;
              this.license.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = transactionConfig.sop.lim.lincense.card.insert.url;
                this.mappingType = 'POST';
                this.license.sysRevision = parseInt(this.license.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.limLicenseId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.limLicenseId = result.data.limLicenseId;
      this.$set(this.attachInfo, 'taskKey', result.data.limLicenseId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$set(this.attachInfoRel, 'taskKey', result.data.limLicenseId)
      this.$set(this.attachInfoRel, 'isSubmit', uid())
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfoRel, 'beforeTaskKey', '')
      this.$set(this.attachInfoRel, 'isRev', false)
      this.getData();
    },
  }
};
</script>
